import React from "react"
import { window } from "browser-monads"
import ReCAPTCHA from "react-google-recaptcha"
import { Link, navigate } from "gatsby"

import Layout from "../components/layout"
import Progress from "../components/progress"
import {
  ajax,
  API_CALL_STATUSES,
  authAjax,
  deleteCookie,
  getCookie,
  getRedirectPathFromUrl,
  redirectViaVerifyAPI,
  setCookie,
} from "../utils"
import CenteredContainer from "../components/CenteredContainer"
import FancyLabel from "../components/FancyLabel"
import FancyInput, { FancyCardHeader } from "../components/FancyInput"

const { captchaSiteKey } = window

const getErrorMessage = err => {
  switch (err && err.status ? err.status : "") {
    case 400:
      return "Current password & new password cannot be the same."
    case 403:
      return "Current password is incorrect."
    case 500:
      return "Password could not be changed due to server error. Please try again later."
    default:
      return "Failed to change the password."
  }
}

class ChangePassword extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fetchDetailsStatus: API_CALL_STATUSES.IDLE,
      changePasswordStatus: API_CALL_STATUSES.IDLE,
      reloginStatus: API_CALL_STATUSES.IDLE,
      showCaptchaMessage: false,
      changePasswordError: "",
      grecaptchaValue: "",
      email: "",
      timer: 5,
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    }

    this.grecaptchaRef = React.createRef()
  }

  componentDidMount() {
    if (getCookie("token")) {
      this.authenticateUser()
    } else {
      navigate(`/login/${getRedirectPathFromUrl()}`)
    }
  }

  validateForm = () => {
    let isValidForm = true
    ;["newPassword", "confirmPassword"].forEach(itemName => {
      const validationResult = this.validateInputItem(itemName)
      if (!validationResult) {
        isValidForm = false
      }
    })
    return isValidForm
  }

  authenticateUser = () => {
    this.setState({
      fetchDetailsStatus: API_CALL_STATUSES.PROGRESS,
    })

    authAjax({
      path: "user/authenticate",
      type: "GET",
      success: res => {
        this.setState({
          email: res.data.email,
          fetchDetailsStatus: API_CALL_STATUSES.SUCCESS,
        })
      },
      error: err => {
        if (err.status === 401 || err.status === 403) {
          deleteCookie("token")
          navigate(`/login/${getRedirectPathFromUrl()}`)
        }
        this.setState({
          fetchDetailsStatus: API_CALL_STATUSES.ERROR,
        })
      },
    })
  }

  updateValue = e => {
    const {
      target: {
        dataset: { id },
        value,
      },
    } = e
    e.preventDefault()
    this.setState({
      [id]: value,
    })
  }

  validateInputItem = inputItemName => {
    const { newPassword, confirmPassword } = this.state

    let regex = /^[A-Za-z]+$/
    switch (inputItemName) {
      case "oldPassword":
        return true
      case "newPassword":
        regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[\d])(?=.*[$@!*\_\-#%\^&])[A-Za-z\d$@!*\_\-#%\^&]{8,}$/
        return !newPassword || !!newPassword.match(regex)
      case "confirmPassword":
        return !confirmPassword || newPassword === confirmPassword
      default:
        return true
    }
  }

  callChangePassword = e => {
    e.preventDefault()
    const {
      oldPassword,
      newPassword,
      confirmPassword,
      grecaptchaValue,
    } = this.state
    if (!grecaptchaValue) {
      this.setState({
        showCaptchaMessage: true,
      })
      return null
    }

    if (!this.validateForm()) {
      return
    }

    const reqObj = {
      oldPassword,
      newPassword,
      confirmPassword,
      "g-recaptcha-response": grecaptchaValue,
      captcha: grecaptchaValue,
    }
    this.setState({
      showCaptchaMessage: false,
      changePasswordStatus: API_CALL_STATUSES.PROGRESS,
    })

    authAjax({
      path: "user/changepassword",
      type: "PUT",
      data: reqObj,
      success: () => {
        this.setState(
          {
            changePasswordStatus: API_CALL_STATUSES.SUCCESS,
          },
          () => {
            this.reloginUser()
          }
        )
      },
      error: err => {
        this.grecaptchaRef.current.reset()
        this.setState({
          grecaptchaValue: "",
          changePasswordError: getErrorMessage(err),
          changePasswordStatus: API_CALL_STATUSES.ERROR,
        })
      },
    })
  }

  reloginUser = () => {
    const { email, newPassword: password } = this.state
    this.setState({
      reloginStatus: API_CALL_STATUSES.PROGRESS,
    })
    ajax({
      path: "user/login",
      type: "POST",
      data: {
        email,
        password,
      },
      success: res => {
        setCookie("token", res.data.token, res.data.expiryAt)
        this.setState(
          {
            reloginStatus: API_CALL_STATUSES.SUCCESS,
            timer: 5,
          },
          () => {
            setInterval(() => {
              const { timer } = this.state
              if (timer <= 1) {
                redirectViaVerifyAPI()
              } else {
                this.setState({
                  timer: timer - 1,
                })
              }
            }, 1000)
          }
        )
      },
      error: () => {
        this.setState({
          reloginStatus: API_CALL_STATUSES.ERROR,
        })
      },
    })
  }

  onCaptchaChange = grecaptchaValue => {
    this.setState({
      grecaptchaValue,
      showCaptchaMessage: !grecaptchaValue,
    })
  }

  render() {
    const {
      fetchDetailsStatus,
      showCaptchaMessage,
      changePasswordStatus,
      changePasswordError,
      reloginStatus,
      oldPassword,
      newPassword,
      confirmPassword,
      timer,
    } = this.state

    return (
      <Layout bgimage stickyFooter dark>
        {(fetchDetailsStatus === API_CALL_STATUSES.PROGRESS ||
          changePasswordStatus === API_CALL_STATUSES.PROGRESS ||
          reloginStatus === API_CALL_STATUSES.PROGRESS) && <Progress />}
        <CenteredContainer>
          {fetchDetailsStatus === API_CALL_STATUSES.SUCCESS ? (
            <React.Fragment>
              {changePasswordStatus !== API_CALL_STATUSES.SUCCESS ? (
                <form
                  id="changePasswordForm"
                  onSubmit={this.callChangePassword}
                >
                  <FancyCardHeader title="Change Account Password" />
                  <div className="inputWrapper">
                    <FancyLabel>Current password</FancyLabel>
                    <FancyInput
                      type="password"
                      data-id="oldPassword"
                      value={oldPassword}
                      onChange={this.updateValue}
                      autoFocus
                      required
                    />
                  </div>
                  <div className="inputWrapper">
                    <FancyLabel>New password</FancyLabel>
                    <FancyInput
                      type="password"
                      data-id="newPassword"
                      value={newPassword}
                      onChange={this.updateValue}
                      required
                    />
                    {!this.validateInputItem("newPassword") && (
                      <div className="text-rose-400 text-center mb-3">
                        <small className="validation">
                          Password must be a minimum of 8 characters long with
                          at least: one uppercase letter, one lowercase letter,
                          one number, and one character among _-!@#$%^&*
                        </small>
                      </div>
                    )}
                  </div>
                  <div className="inputWrapper">
                    <FancyLabel>Confirm new password</FancyLabel>
                    <FancyInput
                      type="password"
                      data-id="confirmPassword"
                      value={confirmPassword}
                      onChange={this.updateValue}
                      required
                    />
                    {!this.validateInputItem("confirmPassword") && (
                      <span className="validation">Passwords do not match</span>
                    )}
                  </div>
                  <div id="captchaWrapper">
                    <ReCAPTCHA
                      ref={this.grecaptchaRef}
                      sitekey={captchaSiteKey}
                      onChange={this.onCaptchaChange}
                    />
                  </div>
                  {showCaptchaMessage && (
                    <span className="validation">
                      Captcha verification is mandatory. Please select the
                      checkbox above.
                    </span>
                  )}

                  {changePasswordStatus === API_CALL_STATUSES.ERROR && (
                    <p id="error">{changePasswordError}</p>
                  )}

                  <button
                    disabled={!this.validateForm()}
                    className="redBtn titleCase"
                  >
                    Continue
                  </button>
                </form>
              ) : (
                <React.Fragment>
                  {reloginStatus === API_CALL_STATUSES.PROGRESS && (
                    <div id="successLogin">
                      <h3>CHANGE PASSWORD</h3>
                      <p className="subText">
                        Successfully changed password
                        <br />
                        Please wait while we log you in
                      </p>
                    </div>
                  )}
                  {reloginStatus === API_CALL_STATUSES.ERROR && (
                    <div id="successLoginError">
                      <h3>CHANGE PASSWORD</h3>
                      <p className="subText">
                        Error logging you back in.
                        <br />
                        <button onClick={this.reloginUser} className="textBtn">
                          Try again
                        </button>
                      </p>
                    </div>
                  )}
                  {reloginStatus === API_CALL_STATUSES.SUCCESS && (
                    <div id="success">
                      <h3>CHANGE PASSWORD</h3>
                      <p className="subText">
                        Successfully changed password
                        <br />
                        Redirecting to{" "}
                        <Link to={`/login/${getRedirectPathFromUrl()}`}>
                          the previous page
                        </Link>{" "}
                        in <span>{timer}</span> sec
                      </p>
                    </div>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          ) : (
            <div id="pending">
              <h3>FETCHING USER DETAILS</h3>
              {fetchDetailsStatus === API_CALL_STATUSES.PROGRESS && (
                <p className="subText" id="detailsWait">
                  Please wait...
                </p>
              )}
              {fetchDetailsStatus === API_CALL_STATUSES.ERROR && (
                <p id="detailsError">
                  <span id="detailsErrorText">
                    An error occured while trying to retrieve user details.{" "}
                  </span>
                  <button onClick={this.authenticateUser} className="textBtn">
                    Try again
                  </button>
                </p>
              )}
            </div>
          )}
        </CenteredContainer>
      </Layout>
    )
  }
}

export default ChangePassword
